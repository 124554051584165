<template>
  <footer class="bg-stone-900 text-white py-8">
    <div class="container mx-auto px-4">
      <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
        <!-- Contact Information -->
        <div id="contact">
          <h3 class="text-xl font-bold mb-4 border-b-2 border-gray-600 pb-2">
            Contact Us
          </h3>
          <p>MA Pesantren Pembangunan Majenang</p>
          <p>Jl. KH. Sufyan Tsauri, Cigaru, Cibeunying</p>
          <p>Kec. Majenang, Cilacap, Jawa Tengah 53257</p>
          <p>
            <a
              href="mailto:
  "
              class="hover:underline hover:text-gray-300"
              >mappmajenang@gmail.com
            </a>
          </p>
          <p>
            <a
              href="https://wa.me/62882006005157"
              target="_blank"
              class="hover:underline hover:text-gray-300"
            >
              (0280) 623944
            </a>
          </p>
        </div>

        <!-- Map Embed -->
        <div class="flex justify-center items-center">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3152.063171515589!2d108.7394011!3d-7.2932668!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x2e6f79351fde3909%3A0xab3cc9ae8643e737!2sMA%20Pesantren%20Pembangunan%20Majenang!5e0!3m2!1sen!2sid!4v1639736516426!5m2!1sen!2sid"
            class="w-full h-48 border border-gray-600 rounded-lg"
            loading="lazy"
          ></iframe>
        </div>

        <!-- Social Media Links -->
        <div>
          <h3 class="text-xl font-bold mb-4 border-b-2 border-gray-600 pb-2">
            Follow Us
          </h3>
          <div class="flex lg:flex-col items-center">
            <div class="flex space-x-2 items-center">
              <a
                href="https://www.facebook.com/mappmajenang"
                target="_blank"
                class="text-blue-600 hover:text-blue-400 transition-colors duration-300"
                aria-label="Facebook"
              >
                <!-- Facebook Icon -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="42"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#3F51B5"
                    d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                  ></path>
                  <path
                    fill="#FFF"
                    d="M34.368,25H31v13h-5V25h-3v-4h3v-2.41c0.002-3.508,1.459-5.59,5.592-5.59H35v4h-2.287C31.104,17,31,17.6,31,18.723V21h4L34.368,25z"
                  ></path>
                </svg>
              </a>

              <a
                href="https://www.instagram.com/mappmajenang"
                target="_blank"
                class="text-pink-600 hover:text-pink-400 transition-colors duration-300"
                aria-label="Instagram"
              >
                <!-- Instagram Icon -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="42"
                  viewBox="0 0 48 48"
                >
                  <path
                    fill="#fd5"
                    d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20C42.014,38.383,38.417,41.986,34.017,41.99z"
                  ></path>
                  <path
                    fill="#fc5245"
                    d="M34.017,41.99l-20,0.019c-4.4,0.004-8.003-3.592-8.008-7.992l-0.019-20c-0.004-4.4,3.592-8.003,7.992-8.008l20-0.019c4.4-0.004,8.003,3.592,8.008,7.992l0.019,20C42.014,38.383,38.417,41.986,34.017,41.99z"
                  ></path>
                  <path
                    fill="#fff"
                    d="M24,31c-3.859,0-7-3.14-7-7s3.141-7,7-7s7,3.14,7,7S27.859,31,24,31z M24,19c-2.757,0-5,2.243-5,5s2.243,5,5,5s5-2.243,5-5S26.757,19,24,19z"
                  ></path>
                  <circle cx="31.5" cy="16.5" r="1.5" fill="#fff"></circle>
                  <path
                    fill="#fff"
                    d="M30,37H18c-3.859,0-7-3.14-7-7V18c0-3.86,3.141-7,7-7h12c3.859,0,7,3.14,7,7v12C37,33.86,33.859,37,30,37z M18,13c-2.757,0-5,2.243-5,5v12c0,2.757,2.243,5,5,5h12c2.757,0,5-2.243,5-5V18c0-2.757-2.243-5-5-5H18z"
                  ></path>
                </svg>
              </a>
              <a
                href="https://www.youtube.com/@mappmajenang"
                target="_blank"
                class="text-red-600 hover:text-red-400 transition-colors duration-300"
                aria-label="YouTube"
              >
                <!-- YouTube Icon -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="42"
                  viewBox="0 0 512 512"
                >
                  <path
                    d="M476.387,144.888c-5.291-19.919-20.878-35.608-40.67-40.933C399.845,94.282,256,94.282,256,94.282 s-143.845,0-179.719,9.674c-19.791,5.325-35.378,21.013-40.668,40.933c-9.612,36.105-9.612,111.438-9.612,111.438 s0,75.334,9.612,111.438c5.29,19.92,20.877,34.955,40.668,40.281C112.155,417.719,256,417.719,256,417.719 s143.845,0,179.717-9.674c19.792-5.326,35.379-20.361,40.67-40.281c9.612-36.104,9.612-111.438,9.612-111.438 S485.999,180.994,476.387,144.888z"
                    style="fill: #ff0000"
                  ></path>
                  <polygon
                    points="208.954,324.723 208.954,187.93 329.18,256.328 "
                    style="fill: #ffffff"
                  ></polygon>
                </svg>
              </a>
            </div>
            <p class="font-md">@mapp_majenang</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Bottom -->
    <div class="text-center text-gray-400 mt-6">
      <p>
        &copy;{{ currentYear }} Madrasah Aliyah Pesantren Pembangunan Majenang.
        All rights reserved.
      </p>
    </div>
  </footer>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "FooterApp",
  setup() {
    const currentYear = ref<number>(new Date().getFullYear());
    return { currentYear };
  },
});
</script>

<style scoped>
iframe {
  border-radius: 0.375rem; /* Rounded corners for the map */
}

a:hover {
  color: #edf2f7; /* Light color for hover effect */
}

a:focus {
  outline: 2px solid #fbbf24; /* Yellow outline for better accessibility */
}
</style>

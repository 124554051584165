import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = {
  id: "gallery",
  class: "px-4 w-full h-auto xl:px-52 lg:px-20 md:px-20 lg:pt-16 mb-12"
}
const _hoisted_2 = { class: "carousel__item mb-6" }
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_Carousel = _resolveComponent("Carousel")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Carousel, _normalizeProps(_guardReactiveProps(_ctx.config)), {
      addons: _withCtx(() => [
        _createVNode(_component_Navigation)
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
          return (_openBlock(), _createBlock(_component_Slide, { key: index }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  src: image,
                  alt: `Banner ${index + 1}`,
                  class: "carousel__image"
                }, null, 8, _hoisted_3)
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 16)
  ]))
}
<template>
  <!-- Carousel Section -->
  <div
    id="gallery"
    class="px-4 w-full h-auto xl:px-52 lg:px-20 md:px-20 lg:pt-16 mb-12"
  >
    <Carousel v-bind="config">
      <Slide v-for="(image, index) in images" :key="index">
        <div class="carousel__item mb-6">
          <img
            :src="image"
            :alt="`Banner ${index + 1}`"
            class="carousel__image"
          />
        </div>
      </Slide>

      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { Carousel, Navigation, Slide } from "vue3-carousel";
import bannerBg1 from "@/assets/images/banner1.jpg";
import bannerBg2 from "@/assets/images/banner2.jpg";
import bannerBg3 from "@/assets/images/banner3.jpg";
import bannerBg4 from "@/assets/images/banner4.jpg";

export default defineComponent({
  name: "GalleryApp",
  components: {
    Carousel,
    Slide,
    Navigation,
  },
  data() {
    return {
      images: [bannerBg1, bannerBg2, bannerBg3, bannerBg4],
      config: {
        autoplay: 3000,
        wrapAround: true,
        itemsToShow: 2,
        transition: 500,
      },
    };
  },
});
</script>

<style scoped>
/* Import Google Fonts (Merriweather untuk gaya serif seperti Times New Roman) */
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap");

.carousel__slide {
  padding: 10px;
}

.carousel__viewport {
  perspective: 2000px;
}

.carousel__track {
  transform-style: preserve-3d;
}

.carousel__slide--sliding {
  transition: 0.5s;
}

.carousel__slide {
  opacity: 0.9;
  transform: rotateY(-20deg) scale(0.9);
}

.carousel__slide--active ~ .carousel__slide {
  transform: rotateY(20deg) scale(0.9);
}

.carousel__slide--prev {
  opacity: 1;
  transform: rotateY(-10deg) scale(0.95);
}

.carousel__slide.carousel__slide--next {
  opacity: 1;
  transform: rotateY(10deg) scale(0.95);
}

.carousel__slide--active {
  opacity: 1;
  transform: rotateY(0) scale(1);
}

.carousel__item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.carousel__item img {
  width: 100%;
  height: 400px;
  max-height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.carousel__viewport {
  height: 400px;
}

.carousel__item {
  height: 100%;
}
</style>

import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"
import _imports_0 from '../assets/images/logo-ma-pp.png'


const _hoisted_1 = { class: "relative sticky top-0 z-10" }
const _hoisted_2 = { class: "flex justify-between items-center w-full h-16 lg:h-24 p-4 lg:px-8 bg-gradient-to-r from-emerald-300 via-emerald-400 to-emerald-500 text-white" }
const _hoisted_3 = { class: "hidden lg:flex gap-8 ml-10 font-nunito" }
const _hoisted_4 = { class: "flex flex-col p-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[10] || (_cache[10] = _createElementVNode("div", { class: "flex w-[80%] items-center gap-2 text-md font-bold font-nunito lg:text-xl" }, [
        _createElementVNode("img", {
          alt: "MA PP Majenang Logo",
          src: _imports_0,
          class: "h-10 lg:h-16"
        }),
        _createTextVNode(" Madrasah Aliyah Pesantren Pembangunan Majenang ")
      ], -1)),
      _createElementVNode("button", {
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleMenu && _ctx.toggleMenu(...args))),
        class: "ml-auto text-2xl md:hidden"
      }, [
        _createVNode(_component_font_awesome_icon, {
          icon: _ctx.isMenuOpen ? ['fas', 'times'] : ['fas', 'bars'],
          class: "text-inherit"
        }, null, 8, ["icon"])
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("a", {
          href: "#home",
          class: _normalizeClass(["py-2 px-4 text-white lg:text-xl lg:font-semibold hover:bg-yellow-400 transition-all duration-200 rounded-lg", { 'bg-yellow-400': _ctx.isActive('home') }]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setActive('home')))
        }, " Home ", 2),
        _createElementVNode("a", {
          href: "#profile",
          class: _normalizeClass(["py-2 px-4 text-white lg:text-xl lg:font-semibold hover:bg-yellow-400 transition-all duration-200 rounded-lg", { 'bg-yellow-400': _ctx.isActive('profile') }]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.setActive('profile')))
        }, " Profile ", 2),
        _createElementVNode("a", {
          href: "#gallery",
          class: _normalizeClass(["py-2 px-4 text-white lg:text-xl lg:font-semibold hover:bg-yellow-400 transition-all duration-200 rounded-lg", { 'bg-yellow-400': _ctx.isActive('gallery') }]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.setActive('gallery')))
        }, " Gallery ", 2),
        _createElementVNode("a", {
          href: "#contact",
          class: _normalizeClass(["py-2 px-4 text-white lg:text-xl lg:font-semibold hover:bg-yellow-400 transition-all duration-200 rounded-lg", { 'bg-yellow-400': _ctx.isActive('contact') }]),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.setActive('contact')))
        }, " Contact ", 2)
      ])
    ]),
    _createElementVNode("nav", {
      class: _normalizeClass(["absolute top-full left-0 w-full bg-gradient-to-r from-emerald-300 via-emerald-400 to-emerald-500 overflow-hidden transform origin-top transition-all duration-700 ease-in-out lg:hidden", { 'scale-y-100': _ctx.isMenuOpen, 'scale-y-0': !_ctx.isMenuOpen }]),
      onTransitionend: _cache[9] || (_cache[9] = 
//@ts-ignore
(...args) => (_ctx.onDropdownTransitionEnd && _ctx.onDropdownTransitionEnd(...args)))
    }, [
      _createElementVNode("ul", _hoisted_4, [
        _createElementVNode("li", {
          class: _normalizeClass([{ 'bg-yellow-400 rounded-lg': _ctx.isActive('home') }, "transition-opacity duration-700"]),
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.setActive('home')))
        }, _cache[11] || (_cache[11] = [
          _createElementVNode("a", {
            href: "#home",
            class: "block py-2 px-4 text-white font-roboto"
          }, "Home", -1)
        ]), 2),
        _createElementVNode("li", {
          class: _normalizeClass([{ 'bg-yellow-400 rounded-lg': _ctx.isActive('profile') }, "transition-opacity duration-700"]),
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.setActive('profile')))
        }, _cache[12] || (_cache[12] = [
          _createElementVNode("a", {
            href: "#profile",
            class: "block py-2 px-4 text-white font-roboto"
          }, "Profile", -1)
        ]), 2),
        _createElementVNode("li", {
          class: _normalizeClass([{ 'bg-yellow-400 rounded-lg': _ctx.isActive('gallery') }, "transition-opacity duration-700"]),
          onClick: _cache[7] || (_cache[7] = ($event: any) => (_ctx.setActive('gallery')))
        }, _cache[13] || (_cache[13] = [
          _createElementVNode("a", {
            href: "#gallery",
            class: "block py-2 px-4 text-white font-roboto"
          }, "Gallery", -1)
        ]), 2),
        _createElementVNode("li", {
          class: _normalizeClass([{ 'bg-yellow-400 rounded-lg': _ctx.isActive('contact') }, "transition-opacity duration-700"]),
          onClick: _cache[8] || (_cache[8] = ($event: any) => (_ctx.setActive('contact')))
        }, _cache[14] || (_cache[14] = [
          _createElementVNode("a", {
            href: "#contact",
            class: "block py-2 px-4 text-white font-roboto"
          }, "Contact", -1)
        ]), 2)
      ])
    ], 34)
  ]))
}
import { createApp } from "vue";
import App from "./App.vue";
import "./tailwind.css";
// import "vue3-toastify/dist/index.css"; // Import CSS untuk toastify
// import "vue3-carousel/dist/carousel.css";
import "vue3-carousel/dist/carousel.css";
import AOS from "aos";
import "aos/dist/aos.css";
import router from "./router";
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons"; // Jika ingin menggunakan solid icons
// import { fab } from '@fortawesome/free-brands-svg-icons'; // Jika ingin menggunakan brand icons
library.add(fas);
// library.add(fab); // Jika ingin menambahkan brand icons

const app = createApp(App);
// Initialize AOS
// AOS.init({
//   duration: 1000, // Animation duration (ms)
//   once: true, // Whether animation should happen only once
// });

// Initialize AOS
app.config.globalProperties.$aosInit = () => {
  AOS.init();
};

app.component("font-awesome-icon", FontAwesomeIcon);
app.use(router);
app.mount("#app");

// Initialize AOS after the app is mounted
app.config.globalProperties.$aosInit();

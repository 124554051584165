<template>
  <div id="home" class="home">
    <CarouselApp />

    <div id="profile" class="w-full mt-4">
      <!-- Banner and About Us Section -->
      <div
        class="flex flex-col items-start px-4 w-full h-auto xl:px-52 lg:px-20 md:px-20 lg:pt-16 lg:flex-row gap-11 mb-20"
      >
        <img
          alt="MA PP Majenang Logo"
          src="../assets/images/banner4.jpg"
          class="w-full lg:w-[500px] h-auto lg:h-[450px] rounded-lg shadow-lg object-cover"
          data-aos="fade-up"
          data-aos-delay="200"
        />
        <div class="flex flex-col justify-center w-full lg:w-1/2">
          <h2
            class="text-emerald-800 font-bold text-lg lg:text-xl text-left mb-3"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            About Us
          </h2>
          <h1
            class="text-emerald-800 text-3xl font-serif text-left mb-2"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            Sejarah Sekolah
          </h1>
          <p
            class="text-justify text-base lg:text-xl text-gray-700"
            data-aos="fade-up"
            data-aos-delay="800"
          >
            MA Pesantren Pembangunan Majenang didirikan sebagai bagian dari
            upaya mencerdaskan kehidupan bangsa melalui pendidikan agama yang
            holistik dan menyeluruh. Berawal dari adanya kebutuhan untuk
            menciptakan lembaga pendidikan yang mampu mengintegrasikan ilmu
            pengetahuan umum dan agama, MA Pesantren Pembangunan Majenang hadir
            sebagai wadah untuk membekali siswa dengan keterampilan,
            pengetahuan, serta akhlak yang baik. Madrasah ini berlokasi di
            kawasan yang sama dengan Pondok Pesantren Pembangunan Miftahul Huda,
            yang sudah lebih dahulu menjadi pusat pendidikan agama di Majenang.
            Seiring dengan perkembangan zaman, MA Pesantren Pembangunan Majenang
            terus berkomitmen untuk menghasilkan lulusan yang tidak hanya cerdas
            dalam ilmu agama, tetapi juga siap menghadapi tantangan global di
            dunia modern.
          </p>
        </div>
      </div>
      <div class="w-full p-10 bg-emerald-500 text-white">
        <!-- <h1 class="text-3xl font-bold text-center">Moto Sekolah</h1> -->
        <p class="text-3xl italic">
          "Mengabdi dalam Ilmu, Mengabdi dalam Akhlak, <br />Menyongsong
          Generasi yang Cerdas dan Berakhlakul Karimah"
        </p>
      </div>
      <div class="container mx-auto px-4 py-16">
        <h1 class="text-3xl font-bold text-center mb-8">
          Kenapa Memilih Kami?
        </h1>
        <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <!-- Card 1 -->
          <div class="bg-emerald-500 rounded-md p-8 text-center">
            <div class="flex items-center justify-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-12 h-12 text-white mb-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z" />
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z" />
              </svg>
              <h2 class="text-xl font-bold text-white mb-2">
                Edukasi Berkualitas
              </h2>
            </div>

            <p class="text-white">
              MA Pesantren Pembangunan Majenang menyediakan pendidikan yang
              mengintegrasikan ilmu agama dan pengetahuan umum, dengan fokus
              pada pembentukan karakter, kecerdasan, dan keterampilan yang
              relevan dengan tantangan global.
            </p>
          </div>

          <!-- Card 2 -->
          <div class="bg-emerald-500 rounded-md p-8 text-center">
            <div class="flex items-center justify-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-12 h-12 text-white mb-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H3a1 1 0 110-2h5V4a1 1 0 011-1z"
                />
              </svg>
              <h2 class="text-xl font-bold text-white mb-2">
                Pengajaran Interaktif
              </h2>
            </div>
            <p class="text-white">
              Dengan pendekatan pengajaran yang interaktif, kami memanfaatkan
              teknologi dan metode pembelajaran kreatif, memungkinkan siswa
              untuk aktif berpartisipasi dan mengembangkan keterampilan berpikir
              kritis serta kreatif.
            </p>
          </div>

          <!-- Card 3 - Sekolah Rekomendasi -->
          <div class="bg-emerald-500 rounded-md p-8 text-center">
            <div class="flex items-center justify-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-12 h-12 text-white mb-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path d="M18 8a6 6 0 11-12 0 6 6 0 0112 0z" />
                <path d="M12 10a1 1 0 00-1-1H9a1 1 0 000 2h2a1 1 0 001-1z" />
              </svg>
              <h2 class="text-xl font-bold text-white mb-2">
                Sekolah Rekomendasi
              </h2>
            </div>
            <p class="text-white">
              MA Pesantren Pembangunan Majenang adalah pilihan tepat bagi orang
              tua yang mencari pendidikan berkualitas dengan pendekatan
              menyeluruh, menggabungkan ilmu agama dan umum.
            </p>
          </div>

          <!-- Card 4 - Akreditasi B -->
          <div class="bg-emerald-500 rounded-md p-8 text-center">
            <div class="flex items-center justify-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="w-12 h-12 text-white mb-4"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  d="M5 3a1 1 0 011 1v2a1 1 0 11-2 0V4a1 1 0 011-1zM3 9a1 1 0 011 1v7a1 1 0 11-2 0V10a1 1 0 011-1zm8-6a1 1 0 011 1v6a1 1 0 11-2 0V4a1 1 0 011-1zm-2 8a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1z"
                />
              </svg>
              <h2 class="text-xl font-bold text-white mb-2">Akreditasi B</h2>
            </div>
            <p class="text-white">
              MA Pesantren Pembangunan Majenang telah meraih Akreditasi B, yang
              membuktikan bahwa kami memenuhi standar kualitas pendidikan yang
              tinggi, baik dalam pengajaran, manajemen, dan fasilitas yang
              mendukung.
            </p>
          </div>
        </div>
      </div>

      <!-- Achievement Section -->
      <!-- <div
        class="flex flex-col px-4 xl:px-52 lg:px-20 lg:py-16 lg:flex-row-reverse gap-12 mb-12"
      > -->
      <div
        class="flex flex-col items-start px-4 w-full h-auto xl:px-52 lg:px-20 md:px-20 lg:pt-16 lg:flex-row-reverse gap-11 mb-12"
      >
        <div
          class="relative w-full lg:w-[500px] h-[300px] lg:h-[500px] rounded-lg overflow-hidden shadow-lg hover:shadow-xl transition-shadow duration-300"
          data-aos="fade-up"
          data-aos-delay="200"
        >
          <img
            src="../assets/images/achievement.jpg"
            alt="achievement"
            class="w-full h-full object-cover lg:object-top"
          />
          <div
            class="absolute bottom-0 left-0 px-6 py-3 bg-emerald-500 text-white w-full"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <h3
              class="text-xl font-bold"
              data-aos="fade-up"
              data-aos-delay="600"
            >
              Prestasi Siswa
            </h3>
            <p
              class="text-justify text-white text-lg"
              data-aos="fade-up"
              data-aos-delay="800"
            >
              Siswa kami meraih berbagai prestasi, mulai dari kompetisi
              akademik, olahraga, hingga seni dan budaya. Keberhasilan ini
              menunjukkan dedikasi dan kerja keras bersama.
            </p>
          </div>
        </div>

        <!-- Additional Section -->
        <div class="flex flex-col gap-5 w-full lg:w-1/2">
          <h1
            class="text-emerald-800 text-3xl text-left font-serif"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            Prestasi Kami
          </h1>
          <p
            class="text-justify text-base lg:text-xl text-gray-600"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            Di MA Pesantren Pembangunan Majenang, kami bangga atas berbagai
            prestasi yang telah diraih oleh siswa-siswi kami, baik dalam bidang
            akademik maupun non-akademik. Dengan dukungan penuh dari pengajar
            dan fasilitas yang mendukung, kami terus mendorong siswa untuk
            mencapai potensi terbaik mereka, berkompetisi, dan membawa pulang
            penghargaan di tingkat lokal, nasional, maupun internasional.
          </p>
        </div>
      </div>

      <!-- Statistics Section -->
      <div
        class="flex flex-col items-center justify-center gap-12 bg-emerald-700 text-white py-12 mb-12"
      >
        <h1 class="text-3xl font-semibold mb-4">Statistik</h1>
        <div class="flex flex-col gap-20 lg:flex-row justify-center">
          <div
            class="flex flex-col items-center gap-6 w-full max-w-md text-center"
          >
            <img
              alt="MA PP Majenang Logo"
              src="../assets/images/logo-ma-pp.png"
              class="w-24 lg:w-52 h-24 lg:h-52"
              data-aos="fade-up"
              data-aos-delay="200"
            />
          </div>
          <div
            class="text-xl font-bold"
            ref="statistik1"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <h1 id="counter1" class="font-bold text-4xl lg:text-5xl">0</h1>
            <p class="text-base lg:text-2xl">Total Peserta Didik</p>
          </div>
          <div
            class="text-xl font-bold"
            ref="statistik2"
            data-aos="fade-up"
            data-aos-delay="800"
          >
            <h1 id="counter2" class="font-bold text-4xl lg:text-5xl">0</h1>
            <p class="text-base lg:text-2xl">Tenaga Pendidik & Kependidikan</p>
          </div>
        </div>

        <p
          class="text-sm lg:text-lg text-center mt-4"
          data-aos="fade-up"
          data-aos-delay="1000"
        >
          Data diperbaharui pada November 2024
        </p>
      </div>

      <!-- Application Sections -->
      <div
        class="flex flex-col items-center justify-center gap-12 mb-12 px-4 xl:px-52 lg:px-20 md:px-20"
      >
        <h1 class="text-3xl font-semibold mb-4">Aplikasi MA PP Majenang</h1>

        <div
          class="flex flex-col lg:flex-row gap-6 lg:gap-24 w-full text-center"
        >
          <div
            class="flex flex-row text-center w-full"
            data-aos="fade-up"
            data-aos-delay="200"
          >
            <h1 class="font-bold text-4xl mb-2">
              <a
                href="https://rdm.kemenag.go.id/"
                target="_blank"
                class="text-blue-600 hover:text-blue-400 transition-colors duration-300"
                aria-label="Facebook"
              >
                <!-- <font-awesome-icon
                  :icon="['fas', 'school']"
                  class="text-inherit"
                /> -->
                <img src="../assets/images/rdm.png" class="h-[250px]" />
                RDM
              </a>
            </h1>
          </div>

          <!-- Emis Section -->
          <div
            class="flex flex-row text-center w-full"
            data-aos="fade-up"
            data-aos-delay="400"
          >
            <h1 class="font-bold text-4xl mb-2">
              <a
                href="https://emis.kemenag.go.id/"
                target="_blank"
                class="text-blue-600 hover:text-blue-400 transition-colors duration-300"
                aria-label="Facebook"
              >
                <!-- <font-awesome-icon
                  :icon="['fas', 'school']"
                  class="text-inherit"
                /> -->
                <img src="../assets/images/emis.png" class="h-42" />
                Emis
              </a>
            </h1>
          </div>

          <!-- Esop Section -->
          <div
            class="flex flex-row text-center w-full"
            data-aos="fade-up"
            data-aos-delay="600"
          >
            <h1 class="font-bold text-4xl mb-2">
              <a
                href="https://esop.mappmajenang.com/"
                target="_blank"
                class="text-blue-600 hover:text-blue-400 transition-colors duration-300"
                aria-label="Facebook"
              >
                <!-- <font-awesome-icon
                  :icon="['fas', 'school']"
                  class="text-inherit"
                /> -->
                <img src="../assets/images/background3.png" class="h-[250px]" />
                Esop
              </a>
            </h1>
          </div>
        </div>
      </div>

      <!-- Image Gallery Section -->
      <!-- <div
        class="flex flex-col lg:flex-row gap-6 lg:gap-10 lg:h-[500px] mb-6 items-center justify-center w-full h-auto mt-4 p-12 bg-emerald-600 text-white"
      >
        <div
          class="relative rounded-lg w-full h-[300px] lg:h-[400px] overflow-hidden shadow-md transition-transform duration-300 transform hover:scale-105"
        >
          <img
            src="../assets/images/pramuka.jpg"
            alt="lorem ipsum"
            class="w-full h-full object-cover"
            data-aos="fade-down"
            data-aos-delay="200"
          />
          <div
            class="absolute bottom-0 left-0 w-full h-full content-center p-6 bg-emerald-400 bg-opacity-50 text-white"
            data-aos="fade-up"
            data-aos-delay="1200"
          >
            <h3
              class="text-3xl font-bold"
              data-aos="fade-down"
              data-aos-delay="1400"
            >
              Ekstrakulikuler
            </h3>
          </div>
        </div>
        <div
          class="relative rounded-lg w-full h-[300px] lg:h-[400px] overflow-hidden shadow-md transition-transform duration-300 transform hover:scale-105"
        >
          <img
            src="../assets/images/achievement-2.jpg"
            alt="lorem ipsum"
            class="w-full h-full object-cover"
            data-aos="zoom-in"
            data-aos-delay="200"
          />
          <div
            class="absolute bottom-0 left-0 w-full h-full content-center p-6 bg-emerald-400 bg-opacity-50 text-white"
            data-aos="fade-up"
            data-aos-delay="1200"
          >
            <h3
              class="text-3xl font-bold"
              data-aos="fade-up"
              data-aos-delay="1400"
            >
              Prestasi
            </h3>
          </div>
        </div>
        <div
          class="relative rounded-lg w-full h-[300px] lg:h-[400px] overflow-hidden shadow-md transition-transform duration-300 transform hover:scale-105"
        >
          <img
            src="../assets/images/facility.jpg"
            alt="lorem ipsum"
            class="w-full h-full object-cover"
            data-aos="fade-up"
            data-aos-delay="200"
          />
          <div
            class="absolute bottom-0 left-0 w-full h-full content-center p-6 bg-emerald-400 bg-opacity-50 text-white"
            data-aos="fade-up"
            data-aos-delay="1200"
          >
            <h3
              class="text-3xl font-bold"
              data-aos="fade-up"
              data-aos-delay="1400"
            >
              Fasilitas
            </h3>
          </div>
        </div>
      </div> -->
    </div>
    <GalleryApp />
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import AOS from "aos";
import "aos/dist/aos.css";
import CarouselApp from "@/components/Carousel.vue";
import GalleryApp from "@/components/Gallery.vue";

export default defineComponent({
  name: "HomeView",
  components: {
    CarouselApp,
    GalleryApp,
  },
  setup() {
    const countUp = (
      id: string,
      start: number,
      end: number,
      duration: number
    ) => {
      const element = document.getElementById(id);
      if (!element) return;
      let startTime: number | null = null;

      const step = (timestamp: number) => {
        if (!startTime) startTime = timestamp;
        const progress = timestamp - startTime;
        const value = Math.min(
          start + (end - start) * (progress / duration),
          end
        );
        element.textContent = Math.floor(value).toString();
        if (progress < duration) {
          window.requestAnimationFrame(step);
        }
      };
      window.requestAnimationFrame(step);
    };

    // Refs to observe when the statistics section comes into view
    const statistik1 = ref<HTMLElement | null>(null);
    const statistik2 = ref<HTMLElement | null>(null);

    // Initialize Intersection Observer
    const initObserver = () => {
      const options = {
        root: null, // relative to viewport
        rootMargin: "0px",
        threshold: 0.5, // 50% of the element must be in view to trigger
      };

      const callback = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Trigger countUp animation when element is in view
            if (entry.target === statistik1.value) {
              countUp("counter1", 0, 214, 3000); // 0 to 259 in 3 seconds
            } else if (entry.target === statistik2.value) {
              countUp("counter2", 0, 20, 3000); // 0 to 20 in 3 seconds
            }
          }
        });
      };

      const observer = new IntersectionObserver(callback, options);

      if (statistik1.value) observer.observe(statistik1.value);
      if (statistik2.value) observer.observe(statistik2.value);
    };

    onMounted(() => {
      // Initialize AOS
      AOS.init({ duration: 1000, easing: "ease-in-out", once: false });

      // Initialize the Intersection Observer
      initObserver();
    });

    return {
      statistik1,
      statistik2,
    };
  },
});
</script>
<style scoped></style>

<template>
  <div
    class="flex flex-col lg:gap-16 lg:flex-row-reverse lg:py-16 lg:px-20 items-start justify-center w-full bg-gradient-to-r from-emerald-400 via-emerald-500 to-emerald-600 text-white"
  >
    <!-- Banner Section -->
    <Carousel v-bind="config" class="w-full">
      <Slide v-for="(image, index) in images" :key="index">
        <div
          class="carousel__item w-full h-[260px] sm:h-[300px] lg:h-full overflow-hidden"
        >
          <img
            :src="image"
            :alt="`Banner ${index + 1}`"
            class="carousel__image object-cover w-full h-full lg:rounded-[30px]"
          />
        </div>
      </Slide>

      <!-- Carousel Navigation and Pagination -->
      <template #addons>
        <Navigation />
        <Pagination class="carousel__pagination" />
      </template>
    </Carousel>

    <!-- Content Section -->
    <div
      class="flex flex-col px-4 py-4 justify-start lg:items-start text-left w-full"
    >
      <h2
        class="text-2xl sm:text-3xl lg:text-6xl font-serif mb-4"
        data-aos="fade-up"
        data-aos-delay="200"
        data-aos-once="true"
      >
        Madrasah Aliyah Pesantren Pembangunan Majenang
      </h2>
      <p
        class="mb-4 text-justify text-sm sm:text-base lg:text-xl"
        data-aos="fade-up"
        data-aos-delay="400"
        data-aos-once="true"
      >
        Salah satu madrasah swasta yang terletak di Kecamatan Majenang,
        Kabupaten Cilacap, Jawa Tengah. Madrasah ini berlokasi di Jl. KH. Sufyan
        Tsauri Po.Box 18 Dusun Cigaru, Desa Cibeunying, Kecamatan Majenang, yang
        berada dalam satu kawasan dengan Pondok Pesantren Pembangunan Miftahul
        Huda Cigaru Majenang. Keberadaan madrasah ini bertujuan untuk memberikan
        pendidikan berkualitas berbasis agama dan teknologi kepada generasi muda
        di wilayah Majenang dan sekitarnya.
      </p>
      <a
        href="https://ppdb.mappmajenang.com/"
        target="_blank"
        rel="PPDB MA PP Majenang"
        class="bg-yellow-500 hover:bg-yellow-400 text-white font-bold py-2 lg:py-3 px-6 lg:px-8 text-lg lg:text-xl border-b-4 border-yellow-700 hover:border-yellow-600 rounded-lg shadow-md transition duration-200 w-fit"
        data-aos="fade-right"
        data-aos-delay="600"
        data-aos-once="true"
      >
        Daftar Sekarang
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import AOS from "aos";
import "aos/dist/aos.css";
import { Carousel, Navigation, Pagination, Slide } from "vue3-carousel";
import bannerBg1 from "@/assets/images/banner1.jpg";
import bannerBg2 from "@/assets/images/banner2.jpg";
import bannerBg3 from "@/assets/images/banner3.jpg";
import bannerBg4 from "@/assets/images/banner4.jpg";

export default defineComponent({
  name: "CarouselApp",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  setup() {
    const images = [bannerBg1, bannerBg2, bannerBg3, bannerBg4];

    // Configuration object for carousel
    const config = ref({
      wrapAround: true,
      autoplay: 5000,
      // Add any other configuration options you need here
    });

    // Initialize AOS for animations
    onMounted(() => {
      AOS.init({
        duration: 1000,
        easing: "ease-in-out",
        once: false, // Set to false to allow re-triggering on scroll
      });
    });

    return {
      images,
      config,
    };
  },
});
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@400;700&display=swap");

/* Carousel item height adjustment for responsive design */
.carousel__item {
  min-height: 260px; /* Default height for small screens */
}

.carousel__image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

/* Additional custom styles for carousel responsiveness */
@media (min-width: 640px) {
  .carousel__item {
    height: 300px; /* Medium screen height */
  }
}

@media (min-width: 1024px) {
  .carousel__item {
    height: 500px; /* Large screen height */
  }
}

@media (min-width: 1280px) {
  .carousel__item {
    height: 550px; /* Extra large screen height */
  }
}
</style>

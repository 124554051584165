<template>
  <div class="relative sticky top-0 z-10">
    <!-- Navbar -->
    <div
      class="flex justify-between items-center w-full h-16 lg:h-24 p-4 lg:px-8 bg-gradient-to-r from-emerald-300 via-emerald-400 to-emerald-500 text-white"
    >
      <div
        class="flex w-[80%] items-center gap-2 text-md font-bold font-nunito lg:text-xl"
      >
        <img
          alt="MA PP Majenang Logo"
          src="../assets/images/logo-ma-pp.png"
          class="h-10 lg:h-16"
        />
        Madrasah Aliyah Pesantren Pembangunan Majenang
      </div>

      <!-- Mobile Menu Toggle Button -->
      <button @click="toggleMenu" class="ml-auto text-2xl md:hidden">
        <font-awesome-icon
          :icon="isMenuOpen ? ['fas', 'times'] : ['fas', 'bars']"
          class="text-inherit"
        />
      </button>

      <!-- Desktop Navbar Links -->
      <div class="hidden lg:flex gap-8 ml-10 font-nunito">
        <a
          href="#home"
          class="py-2 px-4 text-white lg:text-xl lg:font-semibold hover:bg-yellow-400 transition-all duration-200 rounded-lg"
          :class="{ 'bg-yellow-400': isActive('home') }"
          @click="setActive('home')"
        >
          Home
        </a>
        <a
          href="#profile"
          class="py-2 px-4 text-white lg:text-xl lg:font-semibold hover:bg-yellow-400 transition-all duration-200 rounded-lg"
          :class="{ 'bg-yellow-400': isActive('profile') }"
          @click="setActive('profile')"
        >
          Profile
        </a>
        <a
          href="#gallery"
          class="py-2 px-4 text-white lg:text-xl lg:font-semibold hover:bg-yellow-400 transition-all duration-200 rounded-lg"
          :class="{ 'bg-yellow-400': isActive('gallery') }"
          @click="setActive('gallery')"
        >
          Gallery
        </a>
        <a
          href="#contact"
          class="py-2 px-4 text-white lg:text-xl lg:font-semibold hover:bg-yellow-400 transition-all duration-200 rounded-lg"
          :class="{ 'bg-yellow-400': isActive('contact') }"
          @click="setActive('contact')"
        >
          Contact
        </a>
      </div>
    </div>

    <!-- Dropdown Navigation Menu for Mobile -->
    <nav
      class="absolute top-full left-0 w-full bg-gradient-to-r from-emerald-300 via-emerald-400 to-emerald-500 overflow-hidden transform origin-top transition-all duration-700 ease-in-out lg:hidden"
      :class="{ 'scale-y-100': isMenuOpen, 'scale-y-0': !isMenuOpen }"
      @transitionend="onDropdownTransitionEnd"
    >
      <ul class="flex flex-col p-0">
        <li
          :class="{ 'bg-yellow-400 rounded-lg': isActive('home') }"
          @click="setActive('home')"
          class="transition-opacity duration-700"
        >
          <a href="#home" class="block py-2 px-4 text-white font-roboto"
            >Home</a
          >
        </li>
        <li
          :class="{ 'bg-yellow-400 rounded-lg': isActive('profile') }"
          @click="setActive('profile')"
          class="transition-opacity duration-700"
        >
          <a href="#profile" class="block py-2 px-4 text-white font-roboto"
            >Profile</a
          >
        </li>
        <li
          :class="{ 'bg-yellow-400 rounded-lg': isActive('gallery') }"
          @click="setActive('gallery')"
          class="transition-opacity duration-700"
        >
          <a href="#gallery" class="block py-2 px-4 text-white font-roboto"
            >Gallery</a
          >
        </li>
        <li
          :class="{ 'bg-yellow-400 rounded-lg': isActive('contact') }"
          @click="setActive('contact')"
          class="transition-opacity duration-700"
        >
          <a href="#contact" class="block py-2 px-4 text-white font-roboto"
            >Contact</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "NavbarApp",
  setup() {
    const isMenuOpen = ref(false); // To toggle the mobile menu
    const activeItem = ref("home");

    const toggleMenu = () => {
      isMenuOpen.value = !isMenuOpen.value;
    };

    const setActive = (item: string) => {
      activeItem.value = item;
      isMenuOpen.value = false; // Close menu when an item is selected
    };

    const isActive = (item: string) => activeItem.value === item;

    // When the dropdown transition ends, we can apply opacity to the items
    const onDropdownTransitionEnd = () => {
      if (isMenuOpen.value) {
        // Set opacity of the items after dropdown has finished sliding down
        const items = document.querySelectorAll("nav ul li");
        items.forEach((item, index) => {
          (item as HTMLElement).style.opacity = "1";
        });
      }
    };

    return {
      isMenuOpen,
      toggleMenu,
      setActive,
      isActive,
      onDropdownTransitionEnd,
    };
  },
});
</script>

<style scoped>
/* Apply a smooth slide-down effect using transform scaleY */
nav {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.7s ease-in-out;
}

/* This class scales the dropdown when it's open (for mobile) */
.scale-y-100 {
  transform: scaleY(1);
}

/* This class hides the dropdown when it's closed (for mobile) */
.scale-y-0 {
  transform: scaleY(0);
}

/* Ensure that the transition on opacity works for mobile */
li {
  opacity: 0; /* Initially, the items are hidden */
  transition: opacity 0.7s ease-in-out;
}

/* For desktop screens, the nav items should always be visible and in row layout */
@media (min-width: 1024px) {
  nav {
    transform: none; /* Remove the scale effect on desktop */
    opacity: 1;
    background: transparent; /* Make background transparent for desktop */
  }

  .hidden.lg:flex {
    display: flex;
    gap: 10px; /* Add spacing between the nav items */
  }

  li {
    opacity: 1; /* Ensure nav items are visible in desktop mode */
  }

  /* Optional: you can also adjust the size of the nav items for desktop */
  li a {
    font-size: 1rem;
  }
}
</style>

import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, mergeProps as _mergeProps } from "vue"

const _hoisted_1 = { class: "flex flex-col lg:gap-16 lg:flex-row-reverse lg:py-16 lg:px-20 items-start justify-center w-full bg-gradient-to-r from-emerald-400 via-emerald-500 to-emerald-600 text-white" }
const _hoisted_2 = { class: "carousel__item w-full h-[260px] sm:h-[300px] lg:h-full overflow-hidden" }
const _hoisted_3 = ["src", "alt"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Slide = _resolveComponent("Slide")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_Pagination = _resolveComponent("Pagination")!
  const _component_Carousel = _resolveComponent("Carousel", true)!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Carousel, _mergeProps(_ctx.config, { class: "w-full" }), {
      addons: _withCtx(() => [
        _createVNode(_component_Navigation),
        _createVNode(_component_Pagination, { class: "carousel__pagination" })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image, index) => {
          return (_openBlock(), _createBlock(_component_Slide, { key: index }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("img", {
                  src: image,
                  alt: `Banner ${index + 1}`,
                  class: "carousel__image object-cover w-full h-full lg:rounded-[30px]"
                }, null, 8, _hoisted_3)
              ])
            ]),
            _: 2
          }, 1024))
        }), 128))
      ]),
      _: 1
    }, 16),
    _cache[0] || (_cache[0] = _createElementVNode("div", { class: "flex flex-col px-4 py-4 justify-start lg:items-start text-left w-full" }, [
      _createElementVNode("h2", {
        class: "text-2xl sm:text-3xl lg:text-6xl font-serif mb-4",
        "data-aos": "fade-up",
        "data-aos-delay": "200",
        "data-aos-once": "true"
      }, " Madrasah Aliyah Pesantren Pembangunan Majenang "),
      _createElementVNode("p", {
        class: "mb-4 text-justify text-sm sm:text-base lg:text-xl",
        "data-aos": "fade-up",
        "data-aos-delay": "400",
        "data-aos-once": "true"
      }, " Salah satu madrasah swasta yang terletak di Kecamatan Majenang, Kabupaten Cilacap, Jawa Tengah. Madrasah ini berlokasi di Jl. KH. Sufyan Tsauri Po.Box 18 Dusun Cigaru, Desa Cibeunying, Kecamatan Majenang, yang berada dalam satu kawasan dengan Pondok Pesantren Pembangunan Miftahul Huda Cigaru Majenang. Keberadaan madrasah ini bertujuan untuk memberikan pendidikan berkualitas berbasis agama dan teknologi kepada generasi muda di wilayah Majenang dan sekitarnya. "),
      _createElementVNode("a", {
        href: "https://ppdb.mappmajenang.com/",
        target: "_blank",
        rel: "PPDB MA PP Majenang",
        class: "bg-yellow-500 hover:bg-yellow-400 text-white font-bold py-2 lg:py-3 px-6 lg:px-8 text-lg lg:text-xl border-b-4 border-yellow-700 hover:border-yellow-600 rounded-lg shadow-md transition duration-200 w-fit",
        "data-aos": "fade-right",
        "data-aos-delay": "600",
        "data-aos-once": "true"
      }, " Daftar Sekarang ")
    ], -1))
  ]))
}